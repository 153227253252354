import { render, staticRenderFns } from "./PracticeTestEvaluationView.vue?vue&type=template&id=ca29968a&"
import script from "./PracticeTestEvaluationView.vue?vue&type=script&lang=js&"
export * from "./PracticeTestEvaluationView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports